.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background: #eee;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tabs button.active {
    background: #d32f2f;
    color: white;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .teacher-card {
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    
  }
  
  .teacher-card:hover {
    transform: scale(1.05);
  }
  
  .teacher-card img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .teacher-card h3 {
    margin: 10px 0;
    font-size: large;
    color: #5177c4;
    
  }
  @media only screen and (max-width: 1024px) {
    .teacher-card h3{
      font-size: small;
      font-weight: bold;
    }

    .teacher-card{
      
      font-size: x-small;
    }
  }
  
  .teacher-card p {
    color: #666;
    margin-bottom: 10px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .modal img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
  }
  
  .empty-space_init {
    height: 50px;
  }
  
  .empty-space {
    height: 30px;
  }
  
  .founder-card {
    background: #f3f3f3;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
    font-size: xx-small;
  }
  
  .founder-card:hover {
    transform: scale(1.05);
  }
  
  .founder-card img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .founder-card h2 {
    font-size: 22px;
    margin: 10px 0;
  }
  
  .founder-card p {
    font-size: 16px;
    color: #666;
  }

