/* Genel ayarlar */
body {
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

/* Form kapsayıcısı */
.scholarship-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}


/* Başlıklar */
h2 {
  color: #5177c4;
  margin-bottom: 20px;
  text-align: center;
}

h4 {
  color: #343a40;
  margin-top: 30px;
  margin-bottom: 15px;
}

/* Form elemanları */
.form-label {
  font-weight: 500;
  color: #495057;
}

.form-control {
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

/* Seçim kutusu */
.form-select {
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

/* Zorunlu alan uyarısı */
input:required {
  border: 1px solid #dc3545;
}

/* Butonlar */
.scholarship-btn {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  background-color: #5177c4;
  border-color: #5177c4;
}

/* Kaydet ve Gönder butonu */
.btn-primary {
  background-color: #5177c4;
  border-color: #5177c4;
  transition: background-color 0.2s;
}

.scholarship-btn:hover {
  background-color: #0056b3;
}

/* İletişim onayı checkbox stili */
.form-check-label {
  font-size: 14px;
}

/* Responsive tasarım */
@media (max-width: 1024px) {
  .container {
    padding: 10px;
  }

  h2 {
    font-size: 20px;
  }

  button {
    font-size: 14px;
  }
}

.empty-space {
  min-height: 9.1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 31, 63, 1);
}

.empty-space_init {
  min-height: 9.1rem;
}

.empty-space_mid {
  min-height: 2rem;
}

.is-invalid {
  border: 2px solid #dc3545;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {

  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 50%;
  height: 40%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup-content ul {
  list-style: none;
  padding: 0;
}

.popup-content li {
  margin: 10px 0;
}

.popup-content button {
  margin-top: 20px;
  background-color: #5177c4;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #5177c4;
}


.scholarship-link {
  display: inline-block;
  color: #5177c4;
  /* Modern mor renk */
  text-decoration: none;
  /* Alt çizgi kaldır */
  font-weight: bold;
  /* Kalın yazı */
  padding: 10px 15px;
  /* İç boşluk */
  border-radius: 8px;
  /* Yuvarlatılmış köşeler */
  transition: all 0.3s ease;
  /* Geçiş animasyonu */
}

.scholarship-link:hover {
  background-color: #5177c4;
  /* Hover durumda arka plan rengi */
  color: #ffffff;
  /* Beyaz yazı rengi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Hafif gölge efekti */
}

.scholarship-link:active {
  background-color: #554bd3;
  /* Tıklandığında daha koyu renk */
  transform: scale(0.95);
  /* Hafif küçülme efekti */
}

.scholarship-link:focus {
  outline: none;
  /* Focus outline kaldır */
  box-shadow: 0 0 4px 2px rgba(108, 99, 255, 0.6);
  /* Focus gölge efekti */
}

.slider-container-1 {
  height: 100vh;
  /* Ekran yüksekliği kadar */
  overflow: hidden;
  /* Taşan alanları gizle */
}

.slider-container-1 img {
  width: 100%;
  /* Genişliği tamamen kapsar */
  height: 100%;
  /* Yüksekliği tamamen kapsar */
  object-fit: cover;
  /* Görüntüyü kırpmadan sığdırır */
}

.image-fix-size {
  width: 100%;
  flex: auto;
  overflow: hidden;
  margin: 0 auto;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Arkaplanı karartmak için */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Üstte görünmesini sağlamak için */
}

.centered-popup-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px; /* İçeriğin çok genişlemesini engeller */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  /* İçeriği ortalamak isterseniz text-align: center; de ekleyebilirsiniz */
  /* text-align: center; */
}

.centered-lists-container {
  display: flex;
  flex-wrap: wrap;         /* Küçük ekranlarda alt alta geçer */
  gap: 2rem;
  justify-content: center; /* Yatayda ortalanır */
  margin-bottom: 1rem;     /* Butonla liste arasına mesafe */
}

/* Liste stilleri */
.centered-lists-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.scholarship-link {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  /* İsterseniz hover efekti ekleyebilirsiniz */
  /* transition: color 0.2s; */
}

.scholarship-link:hover {
  color: #0056b3;
}
