

.btn-purple-moon {
  background: #7474BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #348AC7, #7474BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  border: 3px solid #eee;
}

.btn-rounded {
  border-radius: 35px;
}

