/* WhatsApp Bileşeninin Stil Dosyası */

.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.whatsapp-button {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #133E87;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.whatsapp-button img {
    width: 40px;
    height: 40px;
}

.main-menu,
.sub-menu {
    position: absolute;
    bottom: 70px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.menu-item {
    width: 260px;
    padding: 10px;
    background-color: #133E87;
    color: white;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
}

.menu-item:hover {
    transform: scale(1.1);
}
