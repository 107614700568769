/* Sidebar.css */
.sidebar {
  position: fixed;
  top: 145px;
  left: -500px;
  width: 250px;
  height: 100%;
  background-color: #001F3F;
  transition: left 0.3s ease;
  z-index: 999;
  padding-top: 20px;
  margin-top: 145nppx;
  border-top-right-radius: 50px
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column; /* Öğelerin alt alta dizilmesi için */
  }

  @media only screen and (max-width:1024px) {
    
    .sidebar ul {

      display: flow;
    }
  
  } 
.sidebar li {
  margin: 15px 0;
}

.sidebar .close-button {
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 5px;
  right: 10px;
}

